import Header from "../../components/header/Header.jsx";

export default function MarbleRacePage() {
  return (
    <>
      <Header home about works contact />

      <h1 className="font-permanent-marker mb-[5px] text-[60px] text-[#1bceff]">
        This is "Marble Race" page.
      </h1>
    </>
  );
}
