/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 ./public/models/stage/stage.glb 
*/

import React, { useEffect, useRef, useMemo } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import {
  useGLTF,
  useAnimations,
  useTexture,
  useVideoTexture,
  Mask,
  useMask,
  MeshDistortMaterial,
  Float,
  Text as DreiText,
} from "@react-three/drei";
import { CuboidCollider, RigidBody } from "@react-three/rapier";
import * as THREE from "three";
import { Root, Text, Image, Container } from "@react-three/uikit";

import waterVertexShader from "../../../shaders/water/vertex.glsl";
import waterFragmentShader from "../../../shaders/water/fragment.glsl";

/**
 * MATERIAL FOR PORTFOLIO WEBSITE BLUEPRINT
 */
const portfolioWebsiteBlueprintTexture = new THREE.TextureLoader().load(
  "/textures/blueprint-portfolio-website/blueprint-portfolio-website.jpg"
);
portfolioWebsiteBlueprintTexture.wrapS = THREE.RepeatWrapping;
portfolioWebsiteBlueprintTexture.wrapT = THREE.RepeatWrapping;
portfolioWebsiteBlueprintTexture.flipY = false;
portfolioWebsiteBlueprintTexture.rotation = Math.PI;

const portfolioWebsiteBlueprintMaterial = new THREE.MeshBasicMaterial({
  toneMapped: false,
  map: portfolioWebsiteBlueprintTexture,
});

/**
 * MATERIAL FOR PICTURE FREAME GLASS
 */
const waterMaterial = new THREE.ShaderMaterial({
  vertexShader: waterVertexShader,
  fragmentShader: waterFragmentShader,
  uniforms: {
    uTime: new THREE.Uniform(0),

    uBigWavesElevation: new THREE.Uniform(0.2),
    uBigWaveFrequency: new THREE.Uniform(new THREE.Vector2(0.4, 0.25)),
    uBigWavesSpeed: new THREE.Uniform(0.05),

    uSmallWavesElevation: new THREE.Uniform(0.15),
    uSmallWavesFrequency: new THREE.Uniform(0.3),
    uSmallWavesSpeed: new THREE.Uniform(0.08),
    uSmallWavesIteration: new THREE.Uniform(4),

    uDepthColor: new THREE.Uniform(new THREE.Color("#1C1C1C")),
    uSurfaceColor: new THREE.Uniform(new THREE.Color("#00abf5")),
    uColorOffset: new THREE.Uniform(0.08),
    uColorMultiplier: new THREE.Uniform(5),

    uMaterialOpacity: new THREE.Uniform(0.8),
  },
  transparent: true,
});

export default function Stage(props) {
  /**
   * REF - SHADER MATERIAL FOR PICTURE FRAME GLASS
   */
  const pictureFrameGlass = useRef();

  useFrame((state, delta) => {
    pictureFrameGlass.current.material.uniforms.uTime.value =
      state.clock.getElapsedTime();
  });

  /**
   * MASK STENCIL
   */
  const stencil = useMask(1);

  /**
   * VIDEO FOR SKY
   */
  const skyVideoTexture = useVideoTexture("/videos/sky/sky.mov");

  /**
   * VIDEOS FOR THE BEAUTY OF TIME PASSING PROJECT
   */
  const springVideoTexture = useVideoTexture(
    "/videos/beauty-of-time-passing/spring.mp4"
  );

  /**
   * VIDEO FOR DIGITAL SCENERY
   */
  const binaryNumbersBackgroundVideo = useVideoTexture(
    "/videos/beauty-of-time-passing/binary-numbers-resize-40perc.mp4"
  );
  binaryNumbersBackgroundVideo.wrapS = THREE.RepeatWrapping;
  binaryNumbersBackgroundVideo.wrapT = THREE.RepeatWrapping;
  binaryNumbersBackgroundVideo.flipY = false;
  binaryNumbersBackgroundVideo.repeat.set(3, 3);

  /**
   * VIDEOS FOR THE 3D VIDUALS
   */
  const silkySphereVideoTexture = useVideoTexture(
    "/videos/three-d-visuals/Silkey_Sphere.mp4"
  );

  silkySphereVideoTexture.wrapS = THREE.RepeatWrapping;
  silkySphereVideoTexture.wrapT = THREE.RepeatWrapping;
  silkySphereVideoTexture.flipY = false;

  const bloomingFlowerVideoTexture = useVideoTexture(
    "/videos/three-d-visuals/Blooming_Flower.mp4"
  );

  bloomingFlowerVideoTexture.wrapS = THREE.RepeatWrapping;
  bloomingFlowerVideoTexture.wrapT = THREE.RepeatWrapping;
  bloomingFlowerVideoTexture.flipY = false;

  const dancingTentacleVideoTexture = useVideoTexture(
    "/videos/three-d-visuals/Dancing_Tentacles.mp4"
  );

  dancingTentacleVideoTexture.wrapS = THREE.RepeatWrapping;
  dancingTentacleVideoTexture.wrapT = THREE.RepeatWrapping;
  dancingTentacleVideoTexture.flipY = false;

  const glassPoppingVideoTexture = useVideoTexture(
    "/videos/three-d-visuals/Glass_Popping.mp4"
  );

  glassPoppingVideoTexture.wrapS = THREE.RepeatWrapping;
  glassPoppingVideoTexture.wrapT = THREE.RepeatWrapping;
  glassPoppingVideoTexture.flipY = false;

  const beautyOfTimePassingVideoTexture = useVideoTexture(
    "/videos/three-d-visuals/Beauty_Of_Time_Passing.mp4"
  );

  beautyOfTimePassingVideoTexture.wrapS = THREE.RepeatWrapping;
  beautyOfTimePassingVideoTexture.wrapT = THREE.RepeatWrapping;
  beautyOfTimePassingVideoTexture.flipY = false;

  const sakuraPetalsVideoTexture = useVideoTexture(
    "/videos/three-d-visuals/Sakura_Petals.mp4"
  );

  sakuraPetalsVideoTexture.wrapS = THREE.RepeatWrapping;
  sakuraPetalsVideoTexture.wrapT = THREE.RepeatWrapping;
  sakuraPetalsVideoTexture.flipY = false;

  const squishyObjectsVideoTexture = useVideoTexture(
    "/videos/three-d-visuals/Squishy_Objects.mp4"
  );

  squishyObjectsVideoTexture.wrapS = THREE.RepeatWrapping;
  squishyObjectsVideoTexture.wrapT = THREE.RepeatWrapping;
  squishyObjectsVideoTexture.flipY = false;

  const sequenceRiverVideoTexture = useVideoTexture(
    "/videos/three-d-visuals/Sequence_River.mp4"
  );

  sequenceRiverVideoTexture.wrapS = THREE.RepeatWrapping;
  sequenceRiverVideoTexture.wrapT = THREE.RepeatWrapping;
  sequenceRiverVideoTexture.flipY = false;

  const all3DVisualsVideoTexture = useVideoTexture(
    "/videos/three-d-visuals/all-visuals-for-camera-screen.mp4"
  );

  all3DVisualsVideoTexture.wrapS = THREE.RepeatWrapping;
  all3DVisualsVideoTexture.wrapT = THREE.RepeatWrapping;
  all3DVisualsVideoTexture.flipY = false;

  /**
   * MODEL -------------------------------------------------------
   */

  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/models/stage/stage.glb");
  const { actions } = useAnimations(animations, group);

  /**
   * USEEFFECT - TYPOGRAPHY ANIMATIONS
   */
  useEffect(() => {
    // Typo A
    actions?.["typo-A-big-circle.001Action"].play();
    actions?.["typo-A-line-horizontal.001Action"].play();
    actions?.["typo-A-line-vertical-left.001Action"].play();
    actions?.["typo-A-line-vertical-right.001Action"].play();

    // Typo B
    actions?.["typo-B-line-horizontal-bottom.001Action"].play();
    actions?.["typo-B-line-horizontal-top.002Action"].play();
    actions?.["typo-B-line-vertical.001Action"].play();
    actions?.["typo-B-small-circle-bottom.001Action"].play();
    actions?.["typo-B-small-circle-top.001Action"].play();

    // Typo C
    actions?.["typo-C-big-circle.001Action"].play();
    actions?.["typo-C-line-horizontal-bottom.001Action"].play();
    actions?.["typo-C-line-horizontal-top.001Action"].play();

    // Mouse
    actions?.["mouse.001Action"].play();

    // Chair
    actions?.["chair-topAction"].play();
    actions?.["chair-bottomAction"].play();
  }, []);

  return (
    <group ref={group} {...props} dispose={null}>
      {/* ------- GLB STAGE MODEL ------- */}

      <group name="Scene">
        {/* AMBIENCE OF LIGHT */}
        <mesh
          name="ambience-of-light-wall"
          geometry={nodes["ambience-of-light-wall"].geometry}
          material={materials["ambience-of-light-dispersion"]}
          position={[-152.866, -0.57, -248.229]}
          rotation={[0, -0.923, 0]}
          scale={0.587}
        />
        <mesh
          name="mesh-ambience-of-light"
          geometry={nodes["mesh-ambience-of-light"].geometry}
          material={materials["texture-ambience-of-light"]}
          position={[-150.028, 43.04, -249.108]}
          rotation={[0, 0.647, 0]}
          scale={0.618}
        />

        {/* BEAUTY OF TIME PASSING */}
        {/* MASK - SCENERY CIRCLE WINDOW */}
        <Mask
          id={1}
          geometry={nodes["scenery-window-mask"].geometry}
          position={[8.845, 28.988, -338.373]}
          rotation={[Math.PI / 2, 0, -0.004]}
          scale={7.427}
        >
          <meshBasicMaterial />
        </Mask>

        <group
          name="circle-window-frame"
          position={[8.774, 28.703, -334.365]}
          rotation={[Math.PI / 2, 0, -0.004]}
          scale={[1.582, 1.034, 1.582]}
        >
          <mesh
            name="Cylinder239"
            geometry={nodes.Cylinder239.geometry}
            material={materials["black-low-poly"]}
          />
          <mesh
            name="Cylinder239_1"
            geometry={nodes.Cylinder239_1.geometry}
            material={materials["paper-screen-white"]}
          />
        </group>

        {/* VIDEO SCREEN - SCENERY REFLECTIONS */}
        <mesh
          name="scenery-reflection-on-the-ground"
          geometry={nodes["scenery-reflection-on-the-ground"].geometry}
          position={[8.165, 5.485, -300.186]}
        >
          <meshBasicMaterial map={springVideoTexture} toneMapped={false} />
        </mesh>

        {/* MASKED - TREE TRUNKS */}
        <mesh
          name="tree-trunks-low-poly"
          geometry={nodes["tree-trunks-low-poly"].geometry}
          position={[-45.805, 14.82, -424.357]}
          rotation={[2.585, -0.595, 0.854]}
          scale={1.382}
        >
          <meshStandardMaterial color="#554236" {...stencil} />
        </mesh>

        {/* MASKED - TREE LEAVES, FLOAT PARTICLE LEAVES */}
        <mesh
          name="float-particles005"
          geometry={nodes["float-particles005"].geometry}
          position={[11.194, 13.085, -387.559]}
          rotation={[Math.PI / 2, 0, 1.352]}
          scale={[22.903, 17.377, 16.426]}
        >
          <MeshDistortMaterial
            distort={0.5}
            speed={1}
            color="#F8C3CD"
            {...stencil}
          />
        </mesh>
        <mesh
          name="tree-leaves-low-poly008"
          geometry={nodes["tree-leaves-low-poly008"].geometry}
          position={[-46.391, 14.363, -425.091]}
          rotation={[2.585, -0.595, 0.854]}
          scale={1.382}
        >
          <MeshDistortMaterial
            distort={0.3}
            speed={1}
            color="#F8C3CD"
            {...stencil}
          />
        </mesh>
        <mesh
          name="tree-leaves-low-poly009"
          geometry={nodes["tree-leaves-low-poly009"].geometry}
          position={[-15.909, 9.415, -455.623]}
          rotation={[2.069, -0.504, -2.562]}
          scale={2.765}
        >
          <MeshDistortMaterial
            distort={0.3}
            speed={1}
            color="#F8C3CD"
            {...stencil}
          />
        </mesh>

        {/* MASKED - DIGITAL SCENERY*/}
        <mesh
          name="digital-world"
          geometry={nodes["digital-world"].geometry}
          position={[8.346, 29.465, -408.117]}
          rotation={[0, 0.004, 0]}
        >
          <meshBasicMaterial
            map={binaryNumbersBackgroundVideo}
            toneMapped={false}
            {...stencil}
          />
        </mesh>

        <group
          name="weather-models"
          position={[22.066, 33.601, -360.556]}
          rotation={[Math.PI / 2, 0, 0.616]}
          scale={0.063}
        >
          <mesh name="clouds002" geometry={nodes.clouds002.geometry}>
            <meshStandardMaterial color="snow" {...stencil} />
          </mesh>
          <mesh name="clouds002_1" geometry={nodes.clouds002_1.geometry}>
            <meshStandardMaterial color="#81C7D4" {...stencil} />
          </mesh>
        </group>

        {/* INTERVENTION IN OUR DISCONNECTION */}
        <mesh
          name="mesh-intervention-in-our-disconnection-models"
          geometry={
            nodes["mesh-intervention-in-our-disconnection-models"].geometry
          }
          material={materials["texture-intervention-in-our-disconnection"]}
          position={[271.166, 6.996, -373.962]}
          rotation={[2.956, -1.034, 3.087]}
          scale={2.089}
        />

        {/* MASU TYPO */}
        {/* TYPO ANIMATION WALLS */}
        <mesh
          name="typo-A-backwall001"
          geometry={nodes["typo-A-backwall001"].geometry}
          material={materials["matt-black"]}
          position={[-245.98, 8.775, -613.353]}
          rotation={[0, -0.741, 0]}
          scale={[57.602, 97.036, 1.286]}
        />
        <mesh
          name="typo-A-line-horizontal001"
          geometry={nodes["typo-A-line-horizontal001"].geometry}
          material={materials["matt-white"]}
          position={[-249.665, 56.08, -608.609]}
          rotation={[0, -0.741, 0]}
          scale={24.341}
        />
        <group
          name="typo-A-big-circle001"
          position={[-254.266, 56.08, -603.579]}
          rotation={[0, -0.741, 0]}
          scale={24.341}
        >
          <mesh
            name="Circle001"
            geometry={nodes.Circle001.geometry}
            material={materials["matt-white"]}
          />
          <mesh
            name="Circle001_1"
            geometry={nodes.Circle001_1.geometry}
            material={materials["matt-black"]}
          />
        </group>
        <mesh
          name="typo-A-line-vertical-left001"
          geometry={nodes["typo-A-line-vertical-left001"].geometry}
          material={materials["matt-white"]}
          position={[-276.606, 56.08, -614.982]}
          rotation={[0, -0.741, 0]}
          scale={24.341}
        />
        <mesh
          name="typo-A-line-vertical-right001"
          geometry={nodes["typo-A-line-vertical-right001"].geometry}
          material={materials["matt-white"]}
          position={[-240.908, 56.08, -582.328]}
          rotation={[0, -0.741, 0]}
          scale={24.341}
        />
        <mesh
          name="typo-B-backwall001"
          geometry={nodes["typo-B-backwall001"].geometry}
          material={materials["matt-black"]}
          position={[-164.761, 8.775, -645.58]}
          rotation={[0, 1.45, 0]}
          scale={[57.602, 97.036, 1.286]}
        />
        <mesh
          name="typo-B-line-horizontal-bottom001"
          geometry={nodes["typo-B-line-horizontal-bottom001"].geometry}
          material={materials["matt-white"]}
          position={[-152.511, 30.329, -644.443]}
          rotation={[0, 1.45, 0]}
          scale={24.341}
        />
        <mesh
          name="typo-B-line-horizontal-top002"
          geometry={nodes["typo-B-line-horizontal-top002"].geometry}
          material={materials["matt-white"]}
          position={[-151.069, 80.783, -644.252]}
          rotation={[0, 1.45, 0]}
          scale={24.341}
        />
        <mesh
          name="typo-B-line-horizontal-middle001"
          geometry={nodes["typo-B-line-horizontal-middle001"].geometry}
          material={materials["matt-white"]}
          position={[-152.511, 55.541, -644.443]}
          rotation={[0, 1.45, 0]}
          scale={24.341}
        />
        <mesh
          name="typo-B-line-vertical001"
          geometry={nodes["typo-B-line-vertical001"].geometry}
          material={materials["matt-white"]}
          position={[-147.73, 56.08, -619.419]}
          rotation={[0, 1.45, 0]}
          scale={24.341}
        />
        <group
          name="typo-B-small-circle-top001"
          position={[-157.104, 68.24, -657.329]}
          rotation={[0, 1.45, 0]}
          scale={25.413}
        >
          <mesh
            name="Circle003"
            geometry={nodes.Circle003.geometry}
            material={materials["matt-black"]}
          />
          <mesh
            name="Circle003_1"
            geometry={nodes.Circle003_1.geometry}
            material={materials["matt-white"]}
          />
        </group>
        <group
          name="typo-B-small-circle-bottom001"
          position={[-157.104, 42.827, -657.329]}
          rotation={[0, 1.45, 0]}
          scale={25.413}
        >
          <mesh
            name="Circle005"
            geometry={nodes.Circle005.geometry}
            material={materials["matt-black"]}
          />
          <mesh
            name="Circle005_1"
            geometry={nodes.Circle005_1.geometry}
            material={materials["matt-white"]}
          />
        </group>
        <mesh
          name="typo-C-backwall001"
          geometry={nodes["typo-C-backwall001"].geometry}
          material={materials["matt-black"]}
          position={[-230.381, 8.775, -699.237]}
          rotation={[Math.PI, -0.425, Math.PI]}
          scale={[57.602, 97.036, 1.286]}
        />
        <group
          name="typo-C-big-circle001"
          position={[-233.479, 56.082, -704.876]}
          rotation={[Math.PI, -0.425, Math.PI]}
          scale={24.341}
        >
          <mesh
            name="Circle006"
            geometry={nodes.Circle006.geometry}
            material={materials["matt-white"]}
          />
          <mesh
            name="Circle006_1"
            geometry={nodes.Circle006_1.geometry}
            material={materials["matt-black"]}
          />
        </group>
        <mesh
          name="typo-C-line-horizontal-bottom001"
          geometry={nodes["typo-C-line-horizontal-bottom001"].geometry}
          material={materials["matt-white"]}
          position={[-236.484, 31.902, -711.501]}
          rotation={[Math.PI, -0.425, Math.PI]}
          scale={24.341}
        />
        <mesh
          name="typo-C-line-horizontal-top001"
          geometry={nodes["typo-C-line-horizontal-top001"].geometry}
          material={nodes["typo-C-line-horizontal-top001"].material}
          position={[-236.484, 80.283, -711.501]}
          rotation={[Math.PI, -0.425, Math.PI]}
          scale={24.341}
        />

        {/* TYPO - HANGING TEXTILE */}
        <mesh
          name="typo-graphic-wood-stick-bottom001"
          geometry={nodes["typo-graphic-wood-stick-bottom001"].geometry}
          material={materials["wood-natural-low-poly"]}
          position={[-382.448, 14.515, -639.907]}
          rotation={[-Math.PI, -0.117, Math.PI / 2]}
          scale={[-0.745, -29.292, -0.745]}
        />
        <mesh
          name="typo-graphic-textile001"
          geometry={nodes["typo-graphic-textile001"].geometry}
          material={materials["typo-graphic-textile"]}
          position={[-382.36, 56.877, -639.917]}
          rotation={[0, 0.117, Math.PI / 2]}
          scale={1.488}
        />
        <mesh
          name="typo-graphic-wood-stick-top001"
          geometry={nodes["typo-graphic-wood-stick-top001"].geometry}
          material={materials["wood-natural-low-poly"]}
          position={[-382.448, 99.239, -639.907]}
          rotation={[-Math.PI, -0.117, Math.PI / 2]}
          scale={[-0.745, -29.292, -0.745]}
        />

        {/* COMFORTING DINNER */}
        <group
          name="mesh-comforting-dinner-cutlery"
          position={[518.716, 40.635, -701.521]}
          rotation={[0.205, -1.208, -0.293]}
          scale={12.497}
        >
          <mesh
            name="Plane106"
            geometry={nodes.Plane106.geometry}
            material={materials["ceramic-white-cutlery-without-texture"]}
          />
          <mesh
            name="Plane106_1"
            geometry={nodes.Plane106_1.geometry}
            material={materials["ceramic-black-cutlery-without-texture"]}
          />
        </group>
        <mesh
          name="mesh-comforting-dinner-humans"
          geometry={nodes["mesh-comforting-dinner-humans"].geometry}
          material={materials["john-mesh-without-texture"]}
          position={[87.188, -55.112, -804.891]}
          rotation={[Math.PI / 2, 0, 0.216]}
          scale={198.515}
        />

        {/* LANDING ROOM, 3D APP VISUALS */}
        <DreiText
          position={[-39, 18, 25]}
          rotation={[0, Math.PI / 2, 0]}
          font="./fonts/shippori-mincho-b1-v21-japanese-regular.woff"
          fontSize={4.0}
          maxWidth={45.0}
          anchorX="center"
          anchorY="middle"
          textAlign="center"
          letterSpacing={0.05}
          lineHeight={1.05}
          color="#FFFFFF"
          // color="#FF028D"
        >
          Working {"\n"} Studio
        </DreiText>

        <group
          scale={3.5}
          position={[-15, 1.5, 25]}
          rotation={[-Math.PI * 0.5, 0, Math.PI * 0.5]}
        >
          <Root>
            <Container flexDirection="column" gap={15} alignItems="center">
              <Image
                src="./images/icons/white-triangle.svg"
                width={400}
                marginTop={0}
              />
              <Text fontWeight="extra-bold" fontSize={80} color="snow">
                ENTER
              </Text>
            </Container>
          </Root>
        </group>

        <mesh
          name="monitor-screen-world"
          geometry={nodes["monitor-screen-world"].geometry}
          material={materials["monitor-screen"]}
          position={[-34.118, 10.519, -2.916]}
          scale={4.516}
        />

        <mesh
          name="monitor-screen-world-pin"
          geometry={nodes["monitor-screen-world-pin"].geometry}
          material={materials["monitor-screen-world-pin"]}
          position={[-39.552, 37.235, 42.702]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={1.599}
        />
        <mesh
          name="monitor-screen-world-pin001"
          geometry={nodes["monitor-screen-world-pin001"].geometry}
          material={materials["monitor-screen-world-pin"]}
          position={[-39.552, 37.235, 10.235]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={1.599}
        />
        <mesh
          name="cutting-mat"
          geometry={nodes["cutting-mat"].geometry}
          material={materials["cutting-mat-material"]}
          position={[171.712, 73.729, 233.398]}
          rotation={[0, -0.267, 0]}
          scale={[81.879, 14.183, 61.409]}
        />

        {/* 3D APP DEV MODEL MESHES */}
        {/* APP FOAM MODELS */}
        <Float
          speed={0.5}
          rotationIntensity={0.075}
          floatIntensity={0.05}
          floatingRange={[0.05, 0.1]}
        >
          <mesh
            name="mesh-marble-race"
            geometry={nodes["mesh-marble-race"].geometry}
            material={materials["texture-3d-app-models"]}
            position={[27.694, 4.452, 156.237]}
            rotation={[2.604, -1.304, 2.535]}
            scale={4.835}
          />
        </Float>

        <Float
          speed={0.5}
          rotationIntensity={0.075}
          floatIntensity={0.05}
          floatingRange={[0.05, 0.1]}
        >
          <mesh
            name="mesh-object-rotterdam-2024"
            geometry={nodes["mesh-object-rotterdam-2024"].geometry}
            material={materials["texture-3d-app-models"]}
            position={[-2.69, 8.908, 232.343]}
            rotation={[1.02, -1.278, 1.047]}
            scale={0.308}
          />
        </Float>

        <Float
          speed={0.5}
          rotationIntensity={0.075}
          floatIntensity={0.05}
          floatingRange={[0.05, 0.1]}
        >
          <mesh
            name="mesh-weather-cereal"
            geometry={nodes["mesh-weather-cereal"].geometry}
            material={materials["texture-3d-app-models"]}
            position={[89.054, 15.512, 245.964]}
            rotation={[-1.993, -0.109, -2.469]}
            scale={0.473}
          />
        </Float>

        <Float
          speed={0.5}
          rotationIntensity={0.075}
          floatIntensity={0.05}
          floatingRange={[0.05, 0.1]}
        >
          <mesh
            name="mesh-donuts-universe"
            geometry={nodes["mesh-donuts-universe"].geometry}
            material={materials["texture-3d-app-models"]}
            position={[138.849, 18.462, 189.618]}
            rotation={[1.564, -0.209, 1.757]}
            scale={156.072}
          />
        </Float>

        {/* RURLER, CUTTING MAT, KNIFE, PENCIL */}
        <group
          name="three-d-app-modeling-utils"
          position={[197.713, 33.683, 275.362]}
          rotation={[-0.184, -0.583, 0.831]}
          scale={[7.888, 5.92, 5.92]}
        >
          <mesh
            name="Plane091"
            geometry={nodes.Plane091.geometry}
            material={materials.frame}
          />
          <mesh
            name="Plane091_1"
            geometry={nodes.Plane091_1.geometry}
            material={materials.grip}
          />
          <mesh
            name="Plane091_2"
            geometry={nodes.Plane091_2.geometry}
            material={materials.blade}
          />
          <mesh
            name="Plane091_3"
            geometry={nodes.Plane091_3.geometry}
            material={materials["foam-board"]}
          />
          <mesh
            name="Plane091_4"
            geometry={nodes.Plane091_4.geometry}
            material={materials["metal-ruler-material"]}
          />
          <mesh
            name="Plane091_5"
            geometry={nodes.Plane091_5.geometry}
            material={materials["pencil-body-metal"]}
          />
          <mesh
            name="Plane091_6"
            geometry={nodes.Plane091_6.geometry}
            material={materials["pencil-body"]}
          />
        </group>

        {/* CLAY SCULPTURE STAND & WORKING OBJECTS, TOOLS */}
        <RigidBody type="fixed">
          <mesh
            name="working-desk-workshop003"
            geometry={nodes["working-desk-workshop003"].geometry}
            material={materials["texture-3d-visuals"]}
            position={[-162.351, 0.422, 2.204]}
            rotation={[0, 0.29, 0]}
          />
          <mesh
            name="working-desk-workshop004"
            geometry={nodes["working-desk-workshop004"].geometry}
            material={materials["texture-3d-visuals"]}
            position={[-147.125, 0.422, -2.341]}
            rotation={[0, 0.29, 0]}
          />
          <mesh
            name="sculpture-stand-square-small001"
            geometry={nodes["sculpture-stand-square-small001"].geometry}
            material={materials["texture-3d-visuals"]}
            position={[-149.915, 0.66, 163.018]}
          />
          <mesh
            name="sculpture-stand-round001"
            geometry={nodes["sculpture-stand-round001"].geometry}
            material={materials["texture-3d-visuals"]}
            position={[-136.577, 0.422, 38.24]}
            rotation={[0, 0.191, 0]}
          />
          <mesh
            name="sculpture-stand-square-big001"
            geometry={nodes["sculpture-stand-square-big001"].geometry}
            material={materials["texture-3d-visuals"]}
            position={[-165.752, 0.422, 56.735]}
            rotation={[0, -0.091, 0]}
          />
        </RigidBody>

        <RigidBody type="fixed">
          <mesh
            name="working-desk-workshop005"
            geometry={nodes["working-desk-workshop005"].geometry}
            material={materials["texture-3d-visuals"]}
            position={[-109.162, 0.422, 17.405]}
          />
          <mesh
            name="clay-board001"
            geometry={nodes["clay-board001"].geometry}
            material={materials["texture-3d-visuals"]}
            position={[-117.304, 11.373, 34.567]}
          />
          <mesh
            name="paper001"
            geometry={nodes.paper001.geometry}
            material={materials["texture-3d-visuals"]}
            position={[-117.288, 10.853, 52.658]}
          />
          <mesh
            name="clay-in-process-01001"
            geometry={nodes["clay-in-process-01001"].geometry}
            material={materials["texture-3d-visuals"]}
            position={[-109.951, 11.989, 12.01]}
          />
          <mesh
            name="clay-in-process-02001"
            geometry={nodes["clay-in-process-02001"].geometry}
            material={materials["texture-3d-visuals"]}
            position={[-108.294, 13.165, 17.118]}
            rotation={[-0.482, -0.017, 0.033]}
          />
          <mesh
            name="sculpture-tool-01001"
            geometry={nodes["sculpture-tool-01001"].geometry}
            material={materials["texture-3d-visuals"]}
            position={[-109.598, 11.082, 0.702]}
          />
          <mesh
            name="sculpture-tool-02001"
            geometry={nodes["sculpture-tool-02001"].geometry}
            material={materials["texture-3d-visuals"]}
            position={[-110.222, 11.057, 3.934]}
            rotation={[0, -1.571, 0]}
          />
        </RigidBody>

        {/* ANALOG TV SHELLS */}
        <group
          name="analog-tv-shells"
          position={[-194.784, 0, 62.408]}
          rotation={[-Math.PI, 0.791, -Math.PI]}
        >
          <RigidBody type="fixed" colliders="hull">
            <mesh
              name="Mesh006"
              geometry={nodes.Mesh006.geometry}
              material={materials["analog-tv-black-matt"]}
            />
          </RigidBody>

          <mesh
            name="Mesh006_1"
            geometry={nodes.Mesh006_1.geometry}
            material={materials["cable-black"]}
          />
        </group>

        {/* CLAY SCULPTURES */}
        <mesh
          name="mesh-equence-river-soil"
          geometry={nodes["mesh-equence-river-soil"].geometry}
          material={materials["texture-3d-visuals"]}
          position={[-154.917, 10.997, 0.247]}
          rotation={[0, 0.29, 0]}
        />
        <mesh
          name="mesh-blooming-flower"
          geometry={nodes["mesh-blooming-flower"].geometry}
          material={materials["texture-3d-visuals"]}
          position={[-151.004, 13.152, 164.199]}
          rotation={[Math.PI / 2, 0, 0.484]}
        />
        <mesh
          name="mesh-silky-sphere"
          geometry={nodes["mesh-silky-sphere"].geometry}
          material={materials["texture-3d-visuals"]}
          position={[-137.005, 22.749, 38.183]}
          rotation={[0, 0.191, 0]}
        />
        <group
          name="mesh-glass-popping"
          position={[-164.224, 18.923, 56.846]}
          rotation={[0, 0.34, 0]}
        >
          <mesh
            name="Plane109"
            geometry={nodes.Plane109.geometry}
            material={materials["texture-3d-visuals"]}
          />
          <mesh
            name="Plane109_1"
            geometry={nodes.Plane109_1.geometry}
            material={materials["texture-3d-visuals"]}
          />
        </group>

        {/* 3D VISUALS TV SCREENS */}
        <mesh
          name="analog-tv-01-modified-square-screen001"
          geometry={nodes["analog-tv-01-modified-square-screen001"].geometry}
          position={[-194.784, 0, 62.408]}
          rotation={[-Math.PI, 0.791, -Math.PI]}
        >
          <meshBasicMaterial map={silkySphereVideoTexture} toneMapped={false} />
        </mesh>
        <mesh
          name="analog-tv-02-modified-horizontal-screen-01001"
          geometry={
            nodes["analog-tv-02-modified-horizontal-screen-01001"].geometry
          }
          position={[-194.784, 0, 62.408]}
          rotation={[-Math.PI, 0.791, -Math.PI]}
        >
          <meshBasicMaterial
            map={dancingTentacleVideoTexture}
            toneMapped={false}
          />
        </mesh>
        <mesh
          name="analog-tv-03-modified-horizontal-screen001"
          geometry={
            nodes["analog-tv-03-modified-horizontal-screen001"].geometry
          }
          position={[-194.784, 0, 62.408]}
          rotation={[-Math.PI, 0.791, -Math.PI]}
        >
          <meshBasicMaterial
            map={bloomingFlowerVideoTexture}
            toneMapped={false}
          />
        </mesh>
        <mesh
          name="analog-tv-03-modified-vertical-big-screen001"
          geometry={
            nodes["analog-tv-03-modified-vertical-big-screen001"].geometry
          }
          position={[-194.784, 0, 62.408]}
          rotation={[-Math.PI, 0.791, -Math.PI]}
        >
          <meshBasicMaterial
            map={beautyOfTimePassingVideoTexture}
            toneMapped={false}
          />
        </mesh>
        <mesh
          name="analog-tv-03-modified-vertical-small-screen001"
          geometry={
            nodes["analog-tv-03-modified-vertical-small-screen001"].geometry
          }
          position={[-194.784, 0, 62.408]}
          rotation={[-Math.PI, 0.791, -Math.PI]}
        >
          <meshBasicMaterial
            map={squishyObjectsVideoTexture}
            toneMapped={false}
          />
        </mesh>
        <mesh
          name="analog-tv-04-modified-horizontal-screen001"
          geometry={
            nodes["analog-tv-04-modified-horizontal-screen001"].geometry
          }
          position={[-194.784, 0, 62.408]}
          rotation={[-Math.PI, 0.791, -Math.PI]}
        >
          <meshBasicMaterial
            map={sakuraPetalsVideoTexture}
            toneMapped={false}
          />
        </mesh>
        <mesh
          name="analog-tv-05-modified-horizontal-screen001"
          geometry={
            nodes["analog-tv-05-modified-horizontal-screen001"].geometry
          }
          position={[-194.784, 0, 62.408]}
          rotation={[-Math.PI, 0.791, -Math.PI]}
        >
          <meshBasicMaterial
            map={glassPoppingVideoTexture}
            toneMapped={false}
          />
        </mesh>
        <mesh
          name="analog-tv-02-modified-horizontal-screen-02001"
          geometry={
            nodes["analog-tv-02-modified-horizontal-screen-02001"].geometry
          }
          position={[-194.784, 0, 62.408]}
          rotation={[-Math.PI, 0.791, -Math.PI]}
        >
          <meshBasicMaterial
            map={sequenceRiverVideoTexture}
            toneMapped={false}
          />
        </mesh>

        {/* PHOTO BOOTH */}
        <mesh
          name="photo-screen001"
          geometry={nodes["photo-screen001"].geometry}
          material={nodes["photo-screen001"].material}
          position={[-168.374, 14.194, 161.602]}
          rotation={[Math.PI / 2, Math.PI / 2, 0]}
          scale={[0.835, 1.179, 0.835]}
        />
        <group
          name="spotlight-01001"
          position={[-116.195, 1.078, 129.386]}
          rotation={[1.567, -0.001, 0.733]}
          scale={0.251}
        >
          <mesh
            name="Box03001"
            geometry={nodes.Box03001.geometry}
            material={materials["dark-grey"]}
          />
          <mesh
            name="Box03001_1"
            geometry={nodes.Box03001_1.geometry}
            material={materials["emission-ceiling-light"]}
          />
        </group>

        <group
          name="spotlight-02001"
          position={[-154.369, 1.078, 199.349]}
          rotation={[1.575, -0.001, -3.076]}
          scale={0.251}
        >
          <mesh
            name="Box03003"
            geometry={nodes.Box03003.geometry}
            material={materials["dark-grey"]}
          />
          <mesh
            name="Box03003_1"
            geometry={nodes.Box03003_1.geometry}
            material={materials["emission-ceiling-light"]}
          />
        </group>

        {/* CAMERA IN 3D VISUAL STUDIO */}
        <group
          name="camera-low-poly"
          position={[-95.521, 19.483, 162.986]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[1.633, 0.544, 1.633]}
        >
          <mesh
            name="Cilindro001"
            geometry={nodes.Cilindro001.geometry}
            material={materials["dark-grey"]}
          />
          <mesh
            name="Cilindro001_1"
            geometry={nodes.Cilindro001_1.geometry}
            material={materials["Material.004"]}
          />
        </group>

        {/* CAMERA SCREEN */}
        <mesh
          name="camera-low-poly-screen"
          geometry={nodes["camera-low-poly-screen"].geometry}
          position={[-95.521, 19.483, 162.986]}
          rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          scale={[1.633, 0.544, 1.633]}
        >
          <meshBasicMaterial
            map={all3DVisualsVideoTexture}
            toneMapped={false}
          />
        </mesh>

        {/* PICTURE FRAME GLASS */}
        <mesh
          ref={pictureFrameGlass}
          position={[13, 22, -47]}
          scale={[18, 12, 1]}
          material={waterMaterial}
        >
          <planeGeometry args={[3, 3, 128, 128]} />
        </mesh>

        <DreiText
          position={[13, 21, -46]}
          // rotation={[0, 0, Math.PI * 0.1]}
          font="./fonts/shippori-mincho-b1-v21-japanese-regular.woff"
          fontSize={4.5}
          maxWidth={7.0}
          anchorX="center"
          anchorY="middle"
          textAlign="center"
          letterSpacing={0.15}
          lineHeight={1.2}
          // color="#FFE302"
          color="#FFFFFF"
        >
          Design Gallery
        </DreiText>

        <group
          scale={3.5}
          position={[13, 1.5, -30]}
          rotation={[-Math.PI * 0.5, 0, 0]}
        >
          <Root>
            <Container flexDirection="column" gap={15} alignItems="center">
              <Image
                src="./images/icons/white-triangle.svg"
                width={400}
                marginTop={0}
              />
              <Text fontWeight="extra-bold" fontSize={80} color="snow">
                ENTER
              </Text>
            </Container>
          </Root>
        </group>

        {/* BOUNDARY WALL */}
        <mesh
          name="boundary-wall"
          geometry={nodes["boundary-wall"].geometry}
          material={materials["black-glossy-material"]}
          position={[0, 0, -48.03]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={-25}
        />

        {/* SCREEN FOR PLAYING THE SKY VIDEO */}
        <mesh
          name="screen-for-sky-movie"
          geometry={nodes["screen-for-sky-movie"].geometry}
          // material={nodes["screen-for-sky-movie"].material}
          position={[153.538, 102.761, 14.466]}
        >
          <meshBasicMaterial map={skyVideoTexture} toneMapped={false} />
        </mesh>

        {/* LANDING ROOM */}
        <mesh
          name="chair-top"
          geometry={nodes["chair-top"].geometry}
          material={materials["analog-tv-black-matt"]}
          position={[-13.978, 0.007, -16.447]}
          rotation={[0, -1.23, 0]}
          scale={19.309}
        />
        <mesh
          name="mouse001"
          geometry={nodes.mouse001.geometry}
          material={materials.mouse}
          position={[-30.103, 16.568, -9.742]}
          rotation={[0, 0.301, 0]}
          scale={5.47}
        />
        <mesh
          name="chair-bottom"
          geometry={nodes["chair-bottom"].geometry}
          material={materials["dark-grey"]}
          position={[-13.978, 0.007, -16.447]}
          rotation={[0, -1.229, 0]}
          scale={19.309}
        />
        <mesh
          name="inner-garden"
          geometry={nodes["inner-garden"].geometry}
          material={materials["texture-room-inner-garden"]}
          position={[151.74, -1.457, 16.468]}
          rotation={[0, 0, Math.PI]}
          scale={[-14.051, -11.638, -63.589]}
        />
        <mesh
          name="room-furniture"
          geometry={nodes["room-furniture"].geometry}
          material={materials["texture-room-furniture"]}
          position={[-31.465, 0.007, -18.065]}
          rotation={[0, -1.571, 0]}
        />
        <mesh
          name="room"
          geometry={nodes.room.geometry}
          material={materials["texture-room-new"]}
        />
        <mesh
          name="software-icons"
          geometry={nodes["software-icons"].geometry}
          material={materials["texutre-software-icons"]}
          position={[-36.979, 27.336, -30.715]}
          rotation={[0, Math.PI / 2, 0]}
        />

        {/* LANDING ROOM ADDED COLLISION OBJECTS */}
        {/* ARCHITECT WORKING DESK */}
        <RigidBody
          type="fixed"
          scale={[10.0, 5.0, 20.0]}
          position={[97.5, 5.0, 37.5]}
          rotation={[0, -Math.PI * 0.28, 0]}
          colliders={false}
        >
          <CuboidCollider args={[1.0, 1.0, 1.0]} />
        </RigidBody>

        {/* 3D WORKING DESK */}
        <RigidBody
          type="fixed"
          scale={[7.5, 5.0, 20.0]}
          position={[-25.0, 5.0, -22.5]}
          rotation={[0, 0, 0]}
          colliders={false}
        >
          <CuboidCollider args={[1.0, 1.0, 1.0]} />
        </RigidBody>

        {/* PLANE FOR THE PORTFOLIO WEBSITE BLUEPRINT IMAGE */}
        <mesh
          name="blueprint-portfolio-website"
          geometry={nodes["blueprint-portfolio-website"].geometry}
          // material={nodes["blueprint-portfolio-website"].material}
          material={portfolioWebsiteBlueprintMaterial}
          position={[94.278, 19.056, 37.278]}
        />

        <mesh
          name="hands-low-poly"
          geometry={nodes["hands-low-poly"].geometry}
          material={materials["human-skin"]}
          position={[-22.627, 2.115, 43.112]}
          rotation={[-2.258, -1.194, -2.383]}
          scale={21.74}
        />
      </group>

      {/* --------- TITLE, TEXT, IMAGE WITH UIKIT -------- */}

      {/* SELF PORTRAIT - IMAGE */}
      <group scale={3} position={[76, 20, -46.5]} rotation={[0, 0, 0]}>
        <Root>
          <Container flexDirection="column" gap={15} alignItems="center">
            <Image
              src="./images/portrait/selfportrait.jpg"
              width={500}
              marginTop={50}
            />
          </Container>
        </Root>
      </group>

      {/* SELF PORTRAIT - MUSEUM LABEL */}
      <group scale={0.6} position={[58.25, 17, -46]} rotation={[0, 0, 0]}>
        <Root>
          <Container flexDirection="column" gap={15} alignItems="flex-start">
            <Text fontWeight="extra-bold" fontSize={60} color="#1C1C1C">
              Self-Portrait, 2024
            </Text>
            <Text fontWeight="medium" fontSize={60} color="#1C1C1C">
              "Multidisciplinary daydreamer"
            </Text>
          </Container>
        </Root>
      </group>

      {/* GALLERY - MUSEUM LABEL */}
      <group scale={0.6} position={[-23.75, 18, -46]} rotation={[0, 0, 0]}>
        <Root>
          <Container flexDirection="column" gap={15} alignItems="flex-start">
            <Text fontWeight="extra-bold" fontSize={60} color="#1C1C1C">
              A gallery
            </Text>
            <Text fontWeight="medium" fontSize={60} color="#1C1C1C">
              "Walk and Explore"
            </Text>
          </Container>
        </Root>
      </group>
    </group>
  );
}

useGLTF.preload("/models/stage/stage.glb");
